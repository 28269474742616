import React, { useState } from "react";
import { graphql } from "gatsby";
// import { PrismicRichText } from "@prismicio/react";
import { Container, Alert, Button, Offcanvas } from "react-bootstrap";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";

const Work = ({ data }) => {
  if (!data) return null;
  const work = data.page.data;

  return (
    <>
      <Seo page={data.page} />
      <Layout>
        <Container
          fluid
          className="homepage d-flex flex-column justify-content-center pt-5 ps-4 ps-sm-5 pe-4 pe-sm-7"
        >
          <div>
            <h1>{work.title}</h1>
          </div>
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query workQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicWork(lang: { eq: $lang }) {
      alternate_languages {
        type
        lang
      }
      lang
      type
      url
      data {
        meta_description
        meta_title
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        title
      }
    }
  }
`;

export default Work;
